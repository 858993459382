import React, { useState } from 'react';
import { Memo, Tag } from '../types';
import './AddMemo.css';

interface AddMemoProps {
    idToken: string
}

const AddMemo: React.FC<AddMemoProps> = ({ idToken }) => {
    const apiEndpoint = process.env.REACT_APP_API_ENDPOINT;
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    const [link, setLink] = useState('');
    const [tags, setTags] = useState<Tag[]>([]);
    const [tagInput, setTagInput] = useState<Tag>({ name: "" });

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        const currentDatetime = new Date().toISOString().slice(0, 19);
        const memo: Memo = {
            external_id: "",
            datetime: currentDatetime,
            title,
            link,
            content,
            tags
        }
        console.log(JSON.stringify(memo))
        fetch(`${apiEndpoint}memos`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": idToken
            },
            body: JSON.stringify(memo),
        })
            .then(response => response.json())
            .then(data => {
                console.log(data);
                setTitle('');
                setContent('');
                setLink('');
                setTags([]);
                setTagInput({ name: "" });
            })
            .catch(error => console.error(error));
    };

    const handleAddTag = () => {
        if (!(tags.some(tag => tag.name === tagInput.name))) {
            setTags([...tags, tagInput]);
        }
        setTagInput({ name: "" });
    };

    const handleRemoveTag = (tagToRemove: string) => {
        setTags(tags.filter(tag => tag.name !== tagToRemove));
    };

    const handleTagInputKeyPress = (e: React.KeyboardEvent) => {
        if (e.key === ',') {
            e.preventDefault();
            if (tagInput.name.trim() !== "") {
                handleAddTag();
            }
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <textarea
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                placeholder="Title" required
            />
            <input
                type="text"
                value={link}
                onChange={(e) => setLink(e.target.value)}
                placeholder="Link" required
            />
            <textarea
                value={content}
                onChange={(e) => setContent(e.target.value)}
                placeholder="Content" required
            />
            <div className="tags-input">
                <input
                    type="text"
                    value={tagInput.name}
                    onChange={(e) => setTagInput({ name: e.target.value })}
                    onKeyDown={handleTagInputKeyPress}
                    placeholder="Add Tag"
                />
                <button type="button" onClick={handleAddTag}>Add Tag</button>
            </div>
            <ul className="tags-list">
                {tags.map((tag, index) => (
                    <li key={index} className="tag-item" onClick={() => handleRemoveTag(tag.name)}>
                        {tag.name}
                    </li>
                ))}
            </ul>
            <button type="submit">Add Memo</button>
        </form>
    );
};

export default AddMemo;
