import React, { useState, useEffect } from 'react';
import './SearchMemo.css';
import { Memo } from '../types';
interface DropDownMenuProps {
    searchTags: string[] | null;
    setSearchTags: (tags: string[] | null) => void;
    tagList: string[];
}


const DropDownMenu: React.FC<DropDownMenuProps> = ({ searchTags, setSearchTags, tagList }) => {
    const [selectTag, setSelectedTag] = useState<string>("");
    const onChange = (tag: string): void => {
        setSelectedTag(tag);
        if (searchTags) {
            if (searchTags.some(oldTag => oldTag !== tag)) {
                setSearchTags([...searchTags, tag]);
            }
        } else {
            setSearchTags([tag]);
        }
    };
    return (
        <select value={selectTag} onChange={(e) => onChange(e?.target.value)}>
            {tagList.map((option, index) => (
                <option key={index} value={option}>
                    {option}
                </option>
            ))}
        </select>
    )
};

interface SearchMemoProps {
    searchTags: string[] | null;
    setSearchTags: (tags: string[] | null) => void;
    tagList: string[];
    setTagList: (tagList: string[]) => void;
    setMemos: (memos: Memo[]) => void;
    idToken: string;
}


const SearchMemo: React.FC<SearchMemoProps> = ({ searchTags, setSearchTags, tagList, setTagList, setMemos, idToken }) => {
    const apiEndpoint = process.env.REACT_APP_API_ENDPOINT;
    useEffect(() => {
        let url: string = `${apiEndpoint}tags`;
        fetch(
            url,
            {
                headers: { "Content-Type": "application/json", "Authorization": idToken }
            })
            .then(response => response.json())
            .then(data => {
                if (data.tags.length > 0) {
                    setTagList(data.tags.sort());
                }
            })
            .catch(error => console.error(error));
        return () => {
            setTagList([]);
        }
    }, [apiEndpoint, setTagList]);
    const handleRemoveTag = (remove: string): void => {
        if (searchTags) {
            const newTags = searchTags.filter((tag: string) => tag !== remove)
            if (newTags.length > 0) {
                setSearchTags(newTags);
            } else {
                setSearchTags(null);
            }
        }
    }
    const listTags = searchTags ? searchTags : [];
    return (
        <div className="search-tags">
            <DropDownMenu
                searchTags={searchTags}
                setSearchTags={setSearchTags}
                tagList={tagList}
            />
            <ul className="tags-list">
                {listTags.map((tag, index) => (
                    <li key={index} className="tag-item" onClick={() => handleRemoveTag(tag)}>
                        {tag}
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default SearchMemo;
