import React, { useState, useEffect } from 'react';
import './QueryMemo.css';
interface QueryTextProps {
    idToken: string;
}

const QueryTextToGemini: React.FC<QueryTextProps> = ({ idToken }) => {
    const [queryText, setQueryText] = useState<string>("");
    const [responseText, setResponseText] = useState<string>("");
    const apiEndpoint = process.env.REACT_APP_API_ENDPOINT;
    const handleTextQuery = (e: React.FormEvent) => {
        e.preventDefault();
        const querybody = {
            query: queryText
        }
        console.log(JSON.stringify(querybody))
        // const testapiEndpoint = "https://kierre.api.not-miso-inside.net/";
        // fetch(`${testapiEndpoint}search/genai`, {
        fetch(`${apiEndpoint}search/genai`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": idToken
            },
            body: JSON.stringify(querybody),
        })
            .then(response => response.json())
            .then(data => {
                console.log(data);
                setResponseText(data["response"]);
            })
            .catch(error => console.error(error));
    };
    return (
        <div className="gemini">
            <form onSubmit={handleTextQuery}>
                <textarea
                    value={queryText}
                    onChange={(e) => setQueryText(e.target.value)}
                    placeholder="Content" required
                />
                <button type="submit">Ask Gemini</button>
                <div className="gemini-response">
                    {responseText}
                </div>
            </form>
        </div>
    );
};

export default QueryTextToGemini;
