import React, { useEffect } from 'react';
import { Memo } from '../types';
import './ListMemo.css';

interface ListMemoProps {
    take: number;
    tags: string[] | null;
    setTags: (tags: string[] | null) => void;
    memos: Memo[];
    setMemos: React.Dispatch<React.SetStateAction<Memo[]>>;
    idToken: string;
}

function shortenLink(link: string): string {
    const THR: number = 60;
    if (link.length < THR) {
        return link;
    } else {
        return `${link.substring(0, THR)}...`;
    }
}

async function fetchMemosWith(endpoint: string, take: number, cursor: string | null, tags: string[] | null, idToken: string): Promise<Memo[]> {
    let url: string = `${endpoint}memos?take=${take}`;
    if (cursor) {
        url += `&cursor=${cursor}`;
    }
    if (tags) {
        const tag_str = tags.join(',');
        url += `&tags=${tag_str}`;
    }
    console.log(`${url}`);
    const response = await fetch(
        url,
        {
            headers: { "Content-Type": "application/json", "Authorization": idToken },
        }
    );
    return response.json();
}

const ListMemo: React.FC<ListMemoProps> = ({ take, tags, setTags, memos, setMemos, idToken }) => {
    const apiEndpoint = process.env.REACT_APP_API_ENDPOINT || "";
    const cursor = memos.length > 0 ? memos[memos.length - 1].external_id : null;

    useEffect(() => {
        fetchMemosWith(apiEndpoint, take, cursor, tags, idToken)
            .then(data => setMemos(data))
            .catch(error => console.error(error));
        return () => {
            setMemos([]);
        };
    }, [take, tags]);

    const loadMoreMemos = () => {
        fetchMemosWith(apiEndpoint, take, cursor, tags, idToken)
            .then(data => {
                if (data.length > 0) {
                    setMemos((prevMemos) => [...prevMemos, ...data]);
                }
            })
            .catch(error => console.error(error));
    };

    const handleDelete = (external_id: string) => {
        fetch(`${apiEndpoint}memos/${external_id}`, {
            method: 'DELETE',
            headers: { "Authorization": idToken }
        })
            .then(response => {
                if (response.ok) {
                    setMemos(memos.filter(memo => memo.external_id !== external_id));
                } else {
                    console.error('Failed to delete memo');
                }
            })
            .catch(error => console.error(error));
    };

    const handleTagClicked = (tag: string) => {
        if (tags) {
            if (tags.every(x => x !== tag)) {
                setMemos([]);
                setTags([...tags, tag]);
            }
        } else {
            setMemos([]);
            setTags([tag]);
        }
    }

    return (
        <div>
            <ul className="memo-list">
                {memos.map(memo => (
                    <li key={memo.external_id} className="card">
                        <div className="card-content">
                            <div className="card-header">
                                <h3>{memo.title}</h3>
                            </div>
                            <div className="card-body">
                                <p><strong>ID:</strong> {memo.external_id}</p>
                                <p><strong>Content:</strong> {memo.content}</p>
                                <p><strong>Date:</strong> {memo.datetime}</p>
                                <p><strong>Link:</strong> <a href={memo.link} target="_blank" rel="noopener noreferrer">{shortenLink(memo.link)}</a></p>
                                <div className="tags">
                                    {memo.tags.map(
                                        (tag, idx) => (
                                            <div key={idx} className="tag" onClick={() => handleTagClicked(tag.name)}>
                                                {tag.name}
                                            </div>)
                                    )}
                                </div>
                            </div>
                            <div className="card-footer">
                                <button onClick={() => handleDelete(memo.external_id)}>Delete</button>
                            </div>
                        </div>
                    </li>
                ))}
            </ul>
            <button className="load-more-button" onClick={loadMoreMemos}>Load More</button>
        </div>
    );
};

export default ListMemo;
