// src/App.tsx
import React, { useState } from 'react';
import AddMemo from './components/AddMemo';
import ListMemo from './components/ListMemo';
import SearchMemo from './components/SearchMemo';
import QueryTextToGemini from './components/QueryMemo';
import { Memo } from './types';
import { useAuth } from 'react-oidc-context';
import './styles.css';

const App: React.FC = () => {
  const auth = useAuth();
  const [searchTags, setSearchTags] = useState<string[] | null>(null);
  const [tagList, setTagList] = useState<string[]>([]);
  const [memos, setMemos] = useState<Memo[]>([]);
  const signOutRedirect = () => {
    const clientId = '2qtokdro5apsvauredsir1sdus';
    const logoutUri = '<logout uri>';
    const cognitoDomain = 'https://ap-northeast-1dpkbwmnyj.auth.ap-northeast-1.amazoncognito.com';
    window.location.href = `${cognitoDomain}/logout?client_id=${clientId}&logout_uri=${encodeURIComponent(logoutUri)}`;
  };

  if (auth.isLoading) {
    return <div>Loading...</div>;
  }

  if (auth.error) {
    return <div>Encountering error... {auth.error.message}</div>;
  }


  if (auth.isAuthenticated) {
    const idToken = auth.user?.id_token || "";
    return (
      <div>
        <div>
          <AddMemo
            idToken={idToken}
          />
        </div>
        <div>
          <QueryTextToGemini
            idToken={idToken}
          />
        </div>
        <div>
          <SearchMemo
            searchTags={searchTags}
            setSearchTags={setSearchTags}
            tagList={tagList}
            setTagList={setTagList}
            setMemos={setMemos}
            idToken={idToken}
          />
        </div>
        <div>
          <ListMemo
            take={1000}
            tags={searchTags}
            setTags={setSearchTags}
            memos={memos}
            setMemos={setMemos}
            idToken={idToken}
          />
        </div>
      </div>
    );
  }

  return (
    <div>
      <div>{process.env.REACT_APP_ROOT_URL}</div>
      <button onClick={() => auth.signinRedirect()}>Sign in</button>
      <button onClick={() => signOutRedirect()}>Sign out</button>
    </div>
  );
};

export default App;
