// index.tsx
import React from 'react';
import ReactDOM from 'react-dom/client';
import { AuthProvider } from 'react-oidc-context';
import App from './App';

const cognitoAuthConfig = {
  authority: 'https://cognito-idp.ap-northeast-1.amazonaws.com/ap-northeast-1_DpkbWmnYj',
  client_id: '2qtokdro5apsvauredsir1sdus',
  redirect_uri: process.env.REACT_APP_ROOT_URL,
  response_type: 'code',
  scope: 'email openid phone',
};

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <AuthProvider {...cognitoAuthConfig}>
      <App />
    </AuthProvider>
  </React.StrictMode>
);
